export const PAGE_LIMIT = 8
export const BIG_LIMIT = 1000

export const configureUserValidation = (utils, dispatch, state) => ({
  initialize: async (user_id) => {
    const topics = { dispatch, request: ['usermanagement', 'generalprofile', 'read'], request_info: { lookup_field: 'user_id', user_id: user_id }, auth: true}
    await utils.action(topics)
  },
  data: {
    usermanagement: state?.usermanagement,
  },
  action: {
  }
})
