import { API_BASE_URL } from "index";
import { toast } from 'react-toastify';
import { Header, Message, Portal } from "semantic-ui-react";

const  path  = {
    register: `/userauth/register/`,
    login: `/userauth/login/`,
    reset: `/userauth/resetpassword/`,
    security_challenge  : `/userauth/resetpassword/`,
    update_password  : `/userauth/resetpassword/`
};

const  onLoggedIn  = (setDialogOpen) => {
   toast.info('Logged in!',);
   window.location.reload()
  setDialogOpen(false)
};
const  onLoginFailed  = () => {
  toast.error('Please check your credentials and try again.', );
};

const  onRegister  = () => {
  toast.success('Registered!',);
};

const  onRegisterFailed  = () => {
  toast.error('Registration failed!', );
};

const onValidEntry = () => {
  // toast.success('Response matches', );
}

const onNotValidEntry =() => {
  toast.error('Please enter a valid answer', );
}

const onEmailDoesntExist = (msg) => {
 toast.error(msg)
}

const  onPassResetFailed  = () => {
  toast.error('Password reset failed!', );
};

const  onPassResetSuccess  = () => {
  toast.success('Password reset successful!',);
};

const onChallengeAdded =(setDialogOpen) => {
  toast.info('Challenge has been added')
  setDialogOpen(false)
}

export const loginProps = (displayLogin ,userFormDialogOpen ,setUserFormDialogOpen)=>({
    isDialogOpen: userFormDialogOpen.login,
      setDialogOpen: (value) => setUserFormDialogOpen({ ...userFormDialogOpen, login: value }),
      type: 'login',
      display: displayLogin,
      baseURL: API_BASE_URL,
      path: path,
      onLoggedIn: onLoggedIn,
      onLoginFailed: onLoginFailed,
      onRegister: onRegister,
      onRegisterFailed: onRegisterFailed,
      onPassResetFailed: onPassResetFailed,
      onPassResetSuccess: onPassResetSuccess,
      onValidEntry : onValidEntry,
      onNotValidEntry : onNotValidEntry,
      onEmailDoesntExist : onEmailDoesntExist,
      onChallengeAdded : onChallengeAdded
})

export const registerProps = (displayRegister,userFormDialogOpen ,setUserFormDialogOpen) => ( {
    isDialogOpen: userFormDialogOpen.register,
      setDialogOpen: (value) => setUserFormDialogOpen({ ...userFormDialogOpen, register: value }),
      type: 'register',
      display: displayRegister,
      baseURL: API_BASE_URL,
      path: path,
      onLoggedIn: onLoggedIn,
      onLoginFailed: onLoginFailed,
      onRegister: onRegister,
      onRegisterFailed: onRegisterFailed,
      onPassResetFailed: onPassResetFailed,
      onPassResetSuccess: onPassResetSuccess,
      onValidEntry : onValidEntry,
      onNotValidEntry : onNotValidEntry,
      onEmailDoesntExist : onEmailDoesntExist,
      onChallengeAdded :onChallengeAdded
}
)
