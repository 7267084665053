import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "semantic-ui-css/semantic.min.css";
import Provider from "modules/wd-api-request-handler";
import Handle from "modules/wd-transit/handle";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
export const API_BASE_URL = "https://api2.webdoko.com/";
// export const API_BASE_URL = "http://localhost:8000/"

root.render(
  <React.StrictMode>
    <Provider baseURL={API_BASE_URL}>
      <App />
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        closeOnClick
        pauseOnHover={true}
      />
      <Handle baseURL={API_BASE_URL} />
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
