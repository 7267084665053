import { useEffect, useState, Suspense, lazy } from "react";
import { Loader } from "semantic-ui-react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
  Link,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import UserForm from "modules/wd-user-auth";
import { loginProps, registerProps } from "modules/wd-layouts/common/userauth";
import { jwtDecode } from "jwt-decode";
import { configureUserValidation } from "modules/wd-aggregator/site";
import { utils } from "modules/wd-api-request-handler";

import "./App.css";

const Dashboard = lazy(() => import("./pages/publisher/landing-page"));
const AddCourse = lazy(() => import("./pages/publisher/add-course"));
const MyCourses = lazy(() => import("./pages/publisher/my-courses"));
const MyChannels = lazy(() => import("./pages/publisher/my-channels"));
const UnverifiedCourses = lazy(() =>
  import("./pages/publisher/unverified-courses")
);
const AddSeries = lazy(() => import("./pages/publisher/add-series"));
const ContentPage = lazy(() => import("./pages/publisher/read-content"));
const DraftedCourses = lazy(() => import("./pages/publisher/drafted-courses"));
const SetupOrganization = lazy(() =>
  import("./pages/publisher/setup-organization")
);
const OrganizationDetail = lazy(() =>
  import("./pages/publisher/organization-detail")
);
const Notification = lazy(() => import("./pages/common/notification"));
const Profile = lazy(() => import("./pages/common/profile"));
const PageNotFound = lazy(() => import("./pages/common/notfound"));

const ScrollToTop = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return null;
};

function App() {
  const [checking_user_validation, loadingUserValidation] = useState(false);
  const [userFormDialogOpen, setUserFormDialogOpen] = useState({
    register: false,
    login: false,
  });

  const displayLogin = {
    logged_in: (name) => (
      <Link to="/profile" style={{ color: "#222" }}>
        My Profile {name ? <span>({name})</span> : ""}
      </Link>
    ),
    not_logged_in: () => (
      <a
        style={{
          cursor: "pointer",
          color: "#222",
          fontSize: 20,
        }}
        onClick={() =>
          setUserFormDialogOpen({ ...userFormDialogOpen, login: true })
        }
      >
        <h1>Welcome to Webdoko publisher!</h1>
        <br />
        <u>Login</u>
      </a>
    ),
    ItemWrapper: ({ children }) => <div> {children} </div>,
  };
  const loggedIn = localStorage.getItem("tk") ? true : false;
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const { initialize, data, action } = configureUserValidation(
    utils,
    dispatch,
    state
  );
  const checkUserValidation = async () => {
    const userData = localStorage.getItem("tk");
    const decoded = jwtDecode(JSON.parse(userData).access);
    const user_id = decoded.user_id;
    loadingUserValidation(true);
    const val = await initialize(user_id);
    loadingUserValidation(false);
  };
  useEffect(() => {
    if (loggedIn && !data?.usermanagement?.generalprofile_item?.profile_type) {
      checkUserValidation();
    } else if (data?.usermanagement?.generalprofile_item?.profile_type) {
      localStorage.setItem(
        "PROFILE_TYPE",
        data?.usermanagement?.generalprofile_item?.profile_type
      );
    }
  }, [loggedIn, data?.usermanagement?.generalprofile_item?.profile_type]);
  if (!loggedIn) {
    return (
      <Router>
        <Routes>
          <Route
            exact
            path="*"
            element={
              <div
                style={{
                  width: "fit-content",
                  margin: "auto",
                  padding: 50,
                  textAlign: "center",
                }}
              >
                <UserForm
                  {...loginProps(
                    displayLogin,
                    userFormDialogOpen,
                    setUserFormDialogOpen
                  )}
                />
              </div>
            }
          />
        </Routes>
      </Router>
    );
  }
  if (loggedIn && checking_user_validation) {
    return (
      <div>
        Check user validation . . .
        <Loader active inline />
      </div>
    );
  }
  if (data?.usermanagement?.generalprofile_item?.profile_type !== "EDUCATOR") {
    const profile_type =
      data?.usermanagement?.generalprofile_item?.profile_type;
    return (
      <div>
        <div style={{ textAlign: "center", margin: 20 }}>
          <h1>Welcome to Webdoko publisher!</h1>
          <h3>
            <u>Update your profile to get access</u>
          </h3>
          <p>
            It looks like your profile type is "
            {profile_type ? profile_type : "NONE"}".
            <br />
            Update your profile to "Educator" to get access.
          </p>
          <a
            href="https://webdoko.com/profile"
            onClick={() => localStorage.removeItem("PROFILE_TYPE")}
          >
            <u>Update profile type</u>
          </a>
        </div>
      </div>
    );
  }
  return (
    <Router>
      <Suspense fallback={<Loader active inline style={{ margin: 10 }} />}>
        <ScrollToTop />
        <Routes>
          <Route exact path="/" element={<Dashboard />} />
          <Route exact path="/create-course" element={<AddCourse />} />
          <Route exact path="/edit-course/:slug" element={<AddCourse />} />

          <Route exact path="/create-series" element={<AddSeries />} />
          <Route exact path="/edit-series/:slug" element={<AddSeries />} />
          <Route exact path="/read-content/:slug" element={<ContentPage />} />

          <Route exact path="/published-items" element={<MyCourses />} />

          <Route exact path="/my-channels" element={<MyChannels />} />

          <Route
            exact
            path="/unverified-items"
            element={<UnverifiedCourses />}
          />
          <Route exact path="/drafted-items" element={<DraftedCourses />} />

          <Route
            exact
            path="/profile"
            element={
              <Profile
                switch_profile_allowed={false}
                show_external_link={false}
              />
            }
          />
          <Route exact path="/notification/" element={<Notification />} />
          <Route
            exact
            path="/setup-organization"
            element={<SetupOrganization />}
          />
          <Route
            exact
            path="/setup-organization/:slug"
            element={<OrganizationDetail />}
          />
          {/*<Route exact path="/edit/:slug" element={<Edit/>} />
            <Route exact path="/new-course" element={<NewCourse/>} />
            <Route exact path="/interoperable/" element={<Interoperable />} />*/}
          <Route exact path="*" element={<PageNotFound />}></Route>
        </Routes>
      </Suspense>
    </Router>
  );
}

export default App;
