//Available Routes : /login/ , /register/ , /reset/
import React, { useState, useEffect } from "react";
import { Form, Header, Modal, Loader } from "semantic-ui-react";
import { setUserData, getUserData } from "./utils/storage";
import axios from "axios";
import { useNavigate } from 'react-router-dom';


const LoginForm = ({
  formType,
  axiosInstance,
  path,
  setDialogOpen,
  setFormType,
  signInFormData,
  setSignInFormData,
  onLoggedIn,
  onLoginFailed,
  disable,
  setDisable,
  loading,
  setLoading,
}) => {
  const signInSuccess = async (data) => {
    setSignInFormData(data);
    setUserData(data);
    const userId = localStorage.getItem("user_id");
    let config = { headers: { Authorization: `Bearer ${data.access}` } };
    onLoggedIn(setDialogOpen);

    // await challengeExist({ userId, config });
    // setDialogOpen(false)
  };

  // const challengeExist = async ({ userId, config }) => {
  //   try {
  //     await axiosInstance.get(
  //       `/userauth/securitychallenge/${userId}/`,
  //       config
  //     );
  //     onLoggedIn(setDialogOpen);
  //   } catch (error) {
  //     console.error("Error checking challenge existence:", error);
  //     setFormType("security_question");
  //   }
  // };

  const signInFailed = () => {
    if (onLoginFailed) {
      onLoginFailed();
    }
  };

  return (
    <Form
      onSubmit={async () => {
        setLoading(true);
        setDisable(true);
        try {
          const signin_user_response = await axiosInstance.post(
            path[formType],
            signInFormData
          );
          signInSuccess(signin_user_response.data);
          setLoading(false);
          setDisable(false);
        } catch (err) {
          console.log(err);
          signInFailed();
          setLoading(false);
          setDisable(false);
        }
      }}
    >
      <Form.Input
        label="Email"
        icon="mail"
        name="email"
        required
        onChange={(e) => {
          setSignInFormData({ ...signInFormData, username: e.target.value });
        }}
      />
      <Form.Input
        type="password"
        label="Password"
        name="password"
        icon="lock"
        required
        onChange={(e) => {
          setSignInFormData({ ...signInFormData, password: e.target.value });
        }}
      />
      <Form.Button type="submit" primary disabled={disable} loading={loading}>
        Sign In
      </Form.Button>
      <div style={{ display: "flex" }}>
        <div>
          <Form.Button
            type="link"
            basic
            onClick={() => setFormType("register")}
          >
            Don't Have an Account? Register
          </Form.Button>
        </div>

        <div>
          <Form.Button type="link" basic onClick={() => setFormType("reset")}>
            Forgot Password?
          </Form.Button>
        </div>
      </div>
    </Form>
  );
};

const RegisterForm = ({
  formType,
  axiosInstance,
  path,
  setFormType,
  signUpFormData,
  setSignUpFormData,
  onRegister,
  onRegisterFailed,
  passErrMsg,
  setPassErrMsg,
  disable,
  setDisable,
  loading,
  setLoading,
}) => {
  useEffect(() => {
    setPassErrMsg(false);
  }, [setPassErrMsg]);

  const signUpFailed = () => {
    if (onRegisterFailed) {
      onRegisterFailed();
    }
  };

  const signUpPassed = () => {
    setFormType("login");
    if (onRegister) {
      onRegister();
    }
  };

  return (
    <Form
      onSubmit={async () => {
        if (signUpFormData.confirmPassword !== signUpFormData.password) {
          setPassErrMsg(true);
        } else {
          setLoading(true);
          setDisable(true);
          try {
            const signup_user_response = await axiosInstance.post(
              path[formType],
              signUpFormData
            );
            setPassErrMsg(false);
            signUpPassed(signup_user_response);
            setLoading(false);
            setDisable(false);
          } catch (err) {
            console.log(err);
            signUpFailed();
            setLoading(false);
            setDisable(false);
          }
        }
      }}
    >
      <Form.Input
        label="First Name"
        icon="user outline"
        name="first_name"
        onChange={(e) => {
          setSignUpFormData({ ...signUpFormData, first_name: e.target.value });
        }}
        required
      />
      <Form.Input
        label="Last Name"
        icon="user outline"
        name="last_name"
        onChange={(e) => {
          setSignUpFormData({ ...signUpFormData, last_name: e.target.value });
        }}
        required
      />
      <Form.Input
        label="Email"
        icon="mail"
        name="email"
        required
        onChange={(e) => {
          setSignUpFormData({ ...signUpFormData, email: e.target.value });
        }}
      />
      <Form.Input
        label="Password"
        icon="lock"
        name="password"
        type="password"
        onChange={(e) => {
          setSignUpFormData({ ...signUpFormData, password: e.target.value });
        }}
        required
      />
      <Form.Input
        label="Confirm Password"
        icon="lock"
        name="confirm_password"
        type="password"
        onChange={(e) => {
          setSignUpFormData({
            ...signUpFormData,
            confirmPassword: e.target.value,
          });
        }}
        required
      />
      {passErrMsg && <p style={{ color: "red" }}>Passwords do not match.</p>}
      <div style={{ display: "flex" }}>
        <div>
          <Form.Button
            type="submit"
            primary
            disabled={disable}
            loading={loading}
          >
            Register
          </Form.Button>
        </div>
        <div>
          <Form.Button basic type="link" onClick={() => setFormType("login")}>
            {" "}
            Already a Member? Sign In
          </Form.Button>
        </div>
      </div>
    </Form>
  );
};

const ResetPassForm = ({
  formType,
  setFormType,
  axiosInstance,
  path,
  resetFormData,
  setResetFormData,
  setDialogOpen,
  onEmailDoesntExist,
  setSecurityAnswer,
  setPasswordChangeQuestion,
  disable,
  setDisable,
  loading,
  setLoading,
}) => {
  const [hasPasswordResetSecurity, setHasPasswordResetSecurity] = useState();
  const emailFailed = (msg, status) => {
    if (onEmailDoesntExist && status === 404) {
      onEmailDoesntExist(msg);
      setHasPasswordResetSecurity(false);
    } else if (onEmailDoesntExist && status === 401) {
      setHasPasswordResetSecurity(true);
    }
  };

  return (
    <Form
      onSubmit={async () => {
        setLoading(true);
        setDisable(true);
        try {
          const reset_pass_response = await axiosInstance.get(
            `${path[formType]}?email=${resetFormData.email}`
          );
          // setFormType("security_challenge");
          setFormType("request_reset");
          setPasswordChangeQuestion(reset_pass_response.data);
          setLoading(false);
          setDisable(false);
        } catch (err) {
          const msg = err.response?.data.message;
          const status = err.response?.status;
          if (status === 404) {
            emailFailed(msg, status);
          } else if (status === 401) {
            emailFailed(msg, status);
          }
          setLoading(false);
          setDisable(false);
        }
      }}
    >
      <Form.Input
        label="Email"
        icon="mail"
        name="email"
        required
        onChange={(e) => {
          setResetFormData({ ...resetFormData, email: e.target.value });
        }}
      />
      {hasPasswordResetSecurity && (
        <p style={{ color: "red" }}>
          {" "}
          <b>
            {" "}
            Password reset option for your account doesn't exist!{" "}
          </b> <br /> Please Contact the administration at contact@webdoko.com
        </p>
      )}
      <Form.Button type="submit" primary disabled={disable} loading={loading}>
        Proceed
      </Form.Button>
    </Form>
  );
};

const SecurityForm = ({
  axiosInstance,
  formType,
  path,
  setFormType,
  resetFormData,
  onValidEntry,
  onNotValidEntry,
  securityAnswer,
  setSecurityToken,
  setSecurityAnswer,
  passwordChangeQuestion,
  disable,
  setDisable,
  loading,
  setLoading,
}) => {
  const securityPass = () => {
    onValidEntry();
  };

  const securityFailed = () => {
    onNotValidEntry();
  };
  return (
    <Form
      onSubmit={async () => {
        setLoading(true);
        setDisable(true);
        try {
          const security_answer = await axiosInstance.post(path[formType], {
            email: resetFormData.email,
            key: securityAnswer.answer,
          });
          setSecurityToken(security_answer.data);
          securityPass();
          setFormType("update_password");
          setLoading(false);
          setDisable(false);
        } catch (err) {
          console.log(err);
          securityFailed();
          setLoading(false);
          setDisable(false);
        }
      }}
    >
      {/*  Put it as what challenge is on API if its OTP or Security question */}
      <Form.Field>
        {" "}
        {passwordChangeQuestion && passwordChangeQuestion.information_text}
      </Form.Field>
      <Form.Input
        required
        onChange={(e) => {
          setSecurityAnswer({ ...securityAnswer, answer: e.target.value });
        }}
      />
      <Form.Button primary type="submit" disabled={disable} loading={loading}>
        Submit
      </Form.Button>
    </Form>
  );
};

const UpdatePassForm = ({
  formType,
  setFormType,
  axiosInstance,
  path,
  securityAnswer,
  resetFormData,
  securityToken,
  passErrMsg,
  setPassErrMsg,
  disable,
  setDisable,
  loading,
  setLoading,
  onPassResetFailed,
  onPassResetSuccess,
}) => {
  const [newPassword, setNewPassword] = useState({
    password: "",
    confirm_password: "",
  });
  const navigate = useNavigate();
  

  useEffect(() => {
    setPassErrMsg(false);
  }, [setPassErrMsg]);

  const resetSucess = () => {
    if (onPassResetSuccess) {
      onPassResetSuccess();
    }
  };

  const resetFailed = () => {
    if (onPassResetFailed) {
      onPassResetFailed();
    }
  };

  return (
    <Form
      onSubmit={async () => {
        if (newPassword.password !== newPassword.confirm_password) {
          setPassErrMsg(true);
          console.log("Password donot match");
        } else {
          setLoading(true);
          setDisable(true);
          try {
            const params = new URLSearchParams(window.location.search)
            await axiosInstance.post(path[formType], {
              password: newPassword.password,
              security_token: params?.get('reset'),
            });
            setPassErrMsg(false);
            setLoading(false);
            resetSucess();
            setDisable(false);
            setFormType("login");
            navigate("/", { replace: true });
          } catch (err) {
            setLoading(false);
            resetFailed();
            setDisable(false);
          }
        }
      }}
    >
      <Form.Input
        label="New Password"
        name="password"
        icon="lock"
        type="password"
        required
        onChange={(e) => {
          setNewPassword({ ...newPassword, password: e.target.value });
        }}
      />
      <Form.Input
        label="Retype Password"
        name="confirm_password"
        icon="lock"
        type="password"
        required
        onChange={(e) => {
          setNewPassword({
            ...newPassword,
            confirm_password: e.target.value,
          });
        }}
      />
      {passErrMsg && <p style={{ color: "red" }}>Passwords do not match.</p>}
      <Form.Button primary type="submit" disabled={disable} loading={loading}>
        {" "}
        Change Password{" "}
      </Form.Button>
    </Form>
  );
};

// const SecurityQuestionForm = ({
//   formType,
//   setDialogOpen,
//   challengeData,
//   setChallengeData,
//   axiosInstance,
//   disable,
//   setDisable,
//   loading,
//   setLoading,
//   onChallengeAdded,
// }) => {
//   const [questions, setQuestions] = useState([]);
//   const options = [
//     {
//       key: "SECURITY_QUESTION",
//       text: "Security Question",
//       value: "SECURITY_QUESTION",
//     },
//     { key: "EMAIL_OTP", text: "Email OTP", value: "EMAIL_OTP", disabled: true },
//     {
//       key: "MOBILE_OTP",
//       text: "Mobile OTP",
//       value: "MOBILE_OTP",
//       disabled: true,
//     },
//   ];
//
//   const questionOptions = questions.map((question, index) => ({
//     key: `question_${index}`,
//     text: question.question,
//     value: index,
//   }));
//
//   const handleSelectChange = async (e, { value }) => {
//     setChallengeData({ ...challengeData, security_type: value });
//     if (value === "SECURITY_QUESTION") {
//       const fetchQuestions = await axiosInstance(
//         `/usermanagement/securityquestion/?limit=100`
//       );
//       setQuestions(fetchQuestions.data?.results);
//     }
//   };
//
//   const onSuccess = () => {
//     onChallengeAdded(setDialogOpen);
//   };
//
//   return (
//     <Form
//       onSubmit={async () => {
//         setLoading(true);
//         setDisable(true);
//         try {
//           const tk = JSON.parse(localStorage.getItem("tk"));
//           const accessToken = tk.access;
//           // setConfig();
//           const config = {
//             headers: { Authorization: `Bearer ${accessToken}` },
//           };
//           const res = await axiosInstance.post(
//             `/userauth/securitychallenge/`,
//             challengeData,
//             config
//           );
//           console.log(challengeData, res);
//           onSuccess();
//           setLoading(false);
//           setDisable(false);
//         } catch (err) {
//           console.log(err);
//           setLoading(false);
//           setDisable(false);
//         }
//       }}
//     >
//       <Form.Select
//         label="Select a Challenge method"
//         options={options}
//         placeholder="Select a Challenge method"
//         onChange={(e, value) => {
//           handleSelectChange(e, value);
//         }}
//       />
//       {questions.length > 0 && (
//         <>
//           <Form.Select
//             label="Select a question"
//             placeholder="Select question"
//             required
//             options={questionOptions}
//             onChange={(e, { value }) => {
//               const selectedQuestion = questions[value];
//               setChallengeData({
//                 ...challengeData,
//                 information_text: selectedQuestion.question,
//               });
//             }}
//           />
//           <Form.Input
//             label="Answer"
//             required
//             onChange={(e, { value }) => {
//               setChallengeData({ ...challengeData, key: value });
//             }}
//           />
//         </>
//       )}
//
//       <Form.Button primary type="submit" disabled={disable} loading={loading}>
//         Submit
//       </Form.Button>
//     </Form>
//   );
// };

const TitleBar = ({ formType }) => {
  return (
    <div style={{ paddingBottom: 20 }}>
      {formType === "login" && (
        <Header as="h2" textAlign="center">
          {" "}
          Login{" "}
        </Header>
      )}
      {formType === "register" && (
        <Header as="h2" textAlign="center">
          {" "}
          Register{" "}
        </Header>
      )}
      {formType === "reset" && (
        <Header as="h2" textAlign="center">
          {" "}
          Reset Password{" "}
        </Header>
      )}
      {/*formType === "security_challenge" && (
        <Header as="h2" textAlign="center">
          {" "}
          Security Challenge{" "}
        </Header>
      )*/}
      {formType === "request_reset" && (
        <Header as="h2" textAlign="center">
          {" "}
          Request Password Reset{" "}
        </Header>
      )}
      {formType === "update_password" && (
        <Header as="h2" textAlign="center">
          {" "}
          Update Password{" "}
        </Header>
      )}
      {/*formType === "security_question" && (
        <Header as="h2" textAlign="center">
          Set Security Challenge
        </Header>
      )*/}
    </div>
  );
};

const RequestReset = ({baseURL, email}) => {
  const [loading, setLoading] = useState(true)
  const [response, setResponse] = useState()
  const axiosInstance = axios.create({ baseURL });
  const challengeExist = async () => {
    setLoading(true)
    try {
      const res = await axiosInstance.get(`/userauth/resetpassword/?email=${email}`);
      setResponse(res);
    } catch (error) {
      console.error("Error checking challenge existence:", error);
    }
    setLoading(false)
  };
  useEffect(()=>{
    challengeExist();
  },[])
  return(
    <div>
      {loading && <><Loader inline active /> Requesting Reset ...</>}
      {!loading && (response.data.account_stage === "REQUESTED_PASSWORD_RESET" ? <>
          <p><b>Request for password reset has been sent.</b><br/>You will shortly receive an email with password reset link.</p>
        </>: (response.data.account_stage === "EMAIL_VERIFICATION_PENDING" ? <>
          <p><b>We have already sent you an email with a reset link.</b><br/>Please check your email and click the link.</p>
        </> : <><b>You have not verified your email yet to be able to change password.</b><br/><br/> Please contact the admin.</>)
      )}
    </div>
  )
}

const UserForm = ({
  isDialogOpen,
  setDialogOpen,
  type,
  display,
  baseURL,
  path,
  onLoggedIn,
  onRegister,
  onPassResetSuccess,
  onLoginFailed,
  onRegisterFailed,
  onPassResetFailed,
  onAnswerFailed,
  onValidEntry,
  onNotValidEntry,
  onEmailDoesntExist,
  onChallengeAdded,
}) => {
  const params = new URLSearchParams(window.location.search)
  const [formType, setFormType] = useState(params?.get('reset') ? 'update_password' : type);
  const [passErrMsg, setPassErrMsg] = useState(false);
  const [securityToken, setSecurityToken] = useState();
  const [loading, setLoading] = useState(false);
  const [disable, setDisable] = useState(false);
  let [config, setConfig] = useState();
  useEffect(() => {
    setFormType(type);
  }, [isDialogOpen]);
  const navigate = useNavigate();

  useEffect(()=>{
    if (!isDialogOpen && params?.get('reset')) {
      setDialogOpen(true)
    } else if (params?.get('reset')) {
      setFormType("update_password")
    }
  }, [isDialogOpen])

  const [signInFormData, setSignInFormData] = useState({
    username: "",
    password: "",
  });

  const [signUpFormData, setSignUpFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    confirmPassword: "",
  });

  const [resetFormData, setResetFormData] = useState({
    email: "",
  });

  const [securityAnswer, setSecurityAnswer] = useState({
    answer: "",
    user_id: "",
  });

  const [challengeData, setChallengeData] = useState({
    security_type: "",
    information_text: "",
    key: "",
  });

  const [passwordChangeQuestion, setPasswordChangeQuestion] = useState();

  const axiosInstance = axios.create({ baseURL });

  const fetchProfileName = () => {
    return null;
  };
  const ItemWrapper = display.ItemWrapper;
  return (
    <>
      <Modal
        open={isDialogOpen}
        dimmer="inverted"
        onClose={() => {
          if (params?.get('reset')) {
            navigate("/", { replace: true });
            setDialogOpen(false)
          } else {
            setDialogOpen(false)
          }
        }}
        style={{ maxWidth: 450, padding: 20 }}
      >
        <div>
          <TitleBar formType={formType} />
        </div>
        {formType === "login" && (
          <LoginForm
            {...{
              formType,
              axiosInstance,
              setDialogOpen,
              path,
              setFormType,
              signInFormData,
              setSignInFormData,
              onLoginFailed,
              onLoggedIn,
              disable,
              setDisable,
              loading,
              setLoading,
            }}
          />
        )}
        {formType === "register" && (
          <RegisterForm
            {...{
              formType,
              axiosInstance,
              path,
              setFormType,
              signUpFormData,
              setSignUpFormData,
              onRegister,
              onRegisterFailed,
              passErrMsg,
              setPassErrMsg,
              disable,
              setDisable,
              loading,
              setLoading,
            }}
          />
        )}
        {formType === "reset" && (
          <ResetPassForm
            {...{
              formType,
              setFormType,
              resetFormData,
              axiosInstance,
              path,
              setResetFormData,
              setDialogOpen,
              onEmailDoesntExist,
              setSecurityAnswer,
              setPasswordChangeQuestion,
              disable,
              setDisable,
              loading,
              setLoading,
            }}
          />
        )}
        {formType === "request_reset" && (
          <RequestReset baseURL={baseURL} email={resetFormData.email}/>
        )}
        {/*formType === "security_challenge" && (
          <SecurityForm
            {...{
              formType,
              setFormType,
              securityAnswer,
              axiosInstance,
              path,
              resetFormData,
              onValidEntry,
              onNotValidEntry,
              setSecurityAnswer,
              setSecurityToken,
              setPasswordChangeQuestion,
              passwordChangeQuestion,
              onAnswerFailed,
              disable,
              setDisable,
              loading,
              setLoading,
            }}
          />
        )*/}
        {formType === "update_password" && (
          <UpdatePassForm
            {...{
              formType,
              setFormType,
              securityAnswer,
              axiosInstance,
              path,
              resetFormData,
              securityToken,
              passErrMsg,
              setPassErrMsg,
              disable,
              setDisable,
              loading,
              setLoading,
              onPassResetFailed,
              onPassResetSuccess,
            }}
          />
        )}
        {/*formType === "security_question" && (
          <SecurityQuestionForm
            {...{
              formType,
              setDialogOpen,
              challengeData,
              setChallengeData,
              axiosInstance,
              disable,
              setDisable,
              loading,
              setLoading,
              onChallengeAdded,
            }}
          />
        )*/}
      </Modal>

      <ItemWrapper>
        {getUserData() && getUserData().access
          ? display.logged_in(fetchProfileName())
          : display.not_logged_in()}
      </ItemWrapper>
    </>
  );
};

export default UserForm;
